import dynamic from "next/dynamic";
import { GetServerSidePropsContext, NextPage } from "next";
import getSettings from "utils/getSettings";
import { ENDPOINT_CONTENT_ID } from "lib/graphql/endpoints";
import useResize from "components/Hooks/useResize";
import { DeviceEnum } from "models/enums";

const HomeSections = dynamic(() => import("components/HomeSections"));
const DynamicPopup = dynamic(() => import("components/DynamicPopup"), { ssr: false });
const RenderScriptsContent = dynamic(() => import("components/RenderScripts/RenderScriptsContent"));

const Home: NextPage = ({ settings, popupSettings }: any) => {
    const { whichDevice } = useResize();
    const isMobileDevice = whichDevice === DeviceEnum.MOBILE;

    const { home } = settings;
    const activePopups = popupSettings?.filter((item: any) => item.value?.enablePopup);
  
    const tpiSettingsJson = settings.thirdParty;
    const { useCriteoTags, criteoScript, criteoAccountId, applyCriteoHomePage } = tpiSettingsJson;

    const criteoScriptComplement = `<script>window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
    { event: "setAccount", account: ${criteoAccountId} },
    { event: "setEmail", email: "" },
    { event: "setSiteType", type: "${isMobileDevice? "m" : "d"}" },
    { event: "viewHome" }
    )</script>`;

    return (
        <>
            {useCriteoTags && applyCriteoHomePage &&
                <>
                    <RenderScriptsContent scriptContent={criteoScript} scriptPosition="scriptOnHead"/>
                    <RenderScriptsContent scriptContent={criteoScriptComplement} scriptPosition="scriptOnHead"/>
                </>
            }
            <HomeSections home={home} />
            {activePopups?.length > 0 && (
                <DynamicPopup popupRecords={activePopups} />
            )}
        </>
    );
};

export const getServerSideProps = async ({
    res,
    req,
}: GetServerSidePropsContext) => {

    res.setHeader(
        "Cache-Control",
        `public, s-maxage=${process.env.CACHE_CONTROL_MAX}, stale-while-revalidate=${process.env.CACHE_CONTROL_MAX}`
    );
    const keyContentIds = [ENDPOINT_CONTENT_ID.DYNAMIC_POPUP_SETTINGS, ENDPOINT_CONTENT_ID.GLOBAL_SEO_META];
    const { settings, websiteContent } = await getSettings(req, keyContentIds);

    const popupItem = websiteContent?.find(item => item?.key === ENDPOINT_CONTENT_ID.DYNAMIC_POPUP_SETTINGS) || null;
    const popupSettings = popupItem && JSON.parse(popupItem?.value ?? '');

    const seoDataString = websiteContent?.find((item) => item?.key === ENDPOINT_CONTENT_ID.GLOBAL_SEO_META) || null;
    const seoData= seoDataString && JSON.parse(seoDataString?.value);

    return {
        props: {
            titlePage: seoData?.titlePage ?? "Home page",
            keywords: seoData?.keywords ?? '',
            metaDescription: seoData?.metaDescription ?? '',
            settings,
            popupSettings,
            page: 'home',
        },
    };
};

export default Home;
